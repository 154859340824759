.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}
.burgericon {
    margin-bottom: 10px;
}

.nav-menu {
    background-color: #ffffff;
    width: 75%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display:flex;
    list-style: none;
    height: 50px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;

}

.nav-text a:hover {
    background-color: #1a83ff;
}

.nav-menu-items {
    width: 75%;
    margin:0;
    padding: 0;
}

.navbar-toggle {
    background-color: #ffffff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

span {
    margin-left: 16px;
}
.close {
    width: 30px;
    height: 30px;
}
a::after {
    content: "";
    padding: 0px;
}