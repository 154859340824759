.container {
    width: 600px;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.labelText {
    width: 65px;
    height: 15px;
    font-family: "BentonSans Regular";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    padding-left: 2px;
}

.textField {
    box-sizing: border-box;
    border: none;
    background: transparent;
    outline: 0;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 95%;
    margin-top: auto;
    margin-left: 7.5px;
}

.textField::placeholder {
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #97999b;
    margin-top: auto;
}

.line {
    box-sizing: border-box;
    width: 600px;
    height: 1px;
    border: solid 1px #53565a;
    background-color: #53565a;
}

.lineError {
    box-sizing: border-box;
    width: 619px;
    height: 1px;
    border: solid 1px #b42c01;
    background-color: #b42c01;
}

.errorText {
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #b42c01;
    margin-top: 8px;
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .container {
        width: auto;
    }
    .line {
        width: auto;
    }
    .lineError {
        width:auto;
    }
}