a {
    color: #006fcf;
    text-decoration: none; /* no underline */
    font-family: 'BentonSans Regular';
    font-size: .75rem;
    font-weight: 700;
}

.separator {
    padding: 5px;
    color: #000000;
    font-weight: 1000;
    margin: 0;
}

.lastLink::after {
    content: "";
}


.footer_container {
    padding:20px ;
    background-color: #ffffff;
    font-size: .75rem;
}

.links {
    text-align: center;
    font-family: 'BentonSans Regular';
    color: #696969;
}

.line1,.line2, .line3 {
    margin: 15px 0;
    text-align: center;
    font-family: 'BentonSans Regular';
    color: #696969;
}

.line1 {
    color: #006fcf;
}

.line3 {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
}