.backDrop {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51,51,51,0.65);
    display: flex;
}

.spinner {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 50%;
    left: 50%;
    animation: spinnerAnime 1.5s linear infinite;
    opacity: 1;
    /* position: fixed; */
    /* left: 0;
    top: 5%;
    right: 0;
    bottom: 0; */
    display: table;
    align-items: stretch;
    /* width: 700px; */
    /* background-color: red; */
    flex-direction: column;
    margin: 0 auto;
    border-radius: 4px;
}

.spinner div {
    box-sizing: border-box;
    /* position: absolute; */
    width: 100%;
    height: 100%;
    border: 8px solid transparent;
    border-top-color: white;
    border-bottom-color: white;
    border-radius: 50%;
}

@keyframes spinnerAnime {
    0% { transform: rotate(0deg); border-width: 10px; }
    50% { transform: rotate(180deg); border-width: 1px; }
    100% { transform: rotate(360deg); border-width: 10px; }
}