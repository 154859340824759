.text {
    width: 100%;
    font-family: "BentonSans Regular";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #006fcf;
    display: flex;
    /* justify-content: center; */
    align-self: center;
    align-items: center;
    margin-left: 22px;
}

.StyleIconsUniversalInvoiceinvoice_active {
    width: 28px;
    height: 28px;
    margin-right: 3px;
    object-fit: contain;
    justify-content: center;
    align-self: center;
  }