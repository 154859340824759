.container {
    width: 722px;
    height: 62px;
    border: solid 1px #870000;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    margin-left: 32px;
    margin-bottom: 9px;
}

.icon {
    flex-grow: 1;
    min-width: 50px;
    max-width: 60px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.errorIcon {
    fill: #870000;
    overflow: visible;
    display: block;
    margin: auto;
}

.closemark {
    position: relative;
    right: 1em;
    top: 1.5em;
}

.svgPath {
    display: block;
    margin: auto;
}

.text {
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #870000;
    display: flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    flex-grow: 12
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .container {
        width: auto;
        max-width: 374px;
    }
}