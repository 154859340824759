.backDrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.65);
    display: flex;
}

.modal {
    font-family: "BentonSans Regular";
    width: 50%;
    height: 40%;
    position: fixed;
    top: 25%;
    left: 25%;
    opacity: 1;
    display: block;
    align-items: stretch;
    flex-direction: column;
    margin: 0 auto;
    padding: 10px;
}

.m1 {
    position: relative;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    background-color: #006fcf;
}

.container {
    font-family: "BentonSans Regular";
    display: block;
    height: 100%;
    background-color: white;
    line-height: 40px;
    padding: 12px;
}

.header {
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #006fcf;
    color: white;
    font-size: 16px;
}

.remove {
    color: black;
    font-weight: bold;
    font-size: 12px;
    top: 3px;
    cursor: pointer;
    text-decoration: underline;
}

.addButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: white;
}

.disabledButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #959798;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: white;
}

.checkboxComponent {
    /* width: 370px; */
    padding-top: 0;
    padding-right: 16px;
    padding-bottom: 0;
    padding-left: 0;
    display: flex;
}

.Checkbox {
    width: 17px;
    height: 17px;
    vertical-align: -16%;
}

.label {
    font-size: 14px;
    line-height: 2;
    color: #53565a;
    margin-left: 5px;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

  .label .tooltiptext {
    visibility: hidden;
    background-color: #006fcf;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    font-size: 12px;
    font-family: "BentonSans Regular";
  
    /* Position the tooltip */
    position: absolute;
    left: 50px;
    top: -35px;
    z-index: 1;
  }
  
  .label:hover .tooltiptext {
    visibility: visible;
  }

.buttongroup {
    display: flex;
    width: 320px;
}

.checkboxgroup {
    display: inline-block;
    margin-top: 4px;
    margin-left: -4px;
}

.travlerGroup {
    display: flex;
    flex-direction: column;
    margin-right: 39px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.assistantModal {
    display: flex;
    flex-direction: row;
}

.assistant {
    margin-left: 52px;
}

.travelerstyle {
    font-size: 14px;
    display: flex;
}

.boldtext {
    font-weight: bold;
}

.verticalLine {
    border-left: 2px solid #c0bebe;
}

.underline {
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
}

.space {
    position: relative;
}

.position {
    position: relative;
    left: 20px
}

.size {
    width: 391px;
}

.scrollableList {
    max-height: 381px;
    overflow: scroll;
}

@media (min-width: 320px) and (max-width:480px) {
    .Checkbox {
        width: 22px;
        height: 22px;
    }

    .label {
        font-size: 16px;
    }
}