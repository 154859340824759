.viewMoreTab {
    width: 90%;
    /* height: 53px; */
    padding: 10px;
    border-radius: 8px;
    /* background-color: #006fcf; */
    border: 2px solid #006fcf;
    color: #006fcf;
    font-weight: 900;
    letter-spacing: 2px;
    /* display:flex; */
    text-align: center;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .viewMoreTab:hover {
    background-color: #006fcf;
    color: #ffffff;
  }