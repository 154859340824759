.text {
    width: 100%;
    font-family: "BentonSans Regular";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #006fcf;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
}