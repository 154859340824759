.messageContainer {
    display: flex;
    align-content: center;
    flex-direction: column;
}

.mainErrorMessage {
    font-family: 'BentonSans Regular';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #00175a;
    margin-top: 32px;
}

.tryAgain {
    width: 180px;
    height: 50px;
    margin: 64px auto 0;
    border-radius: 4px;
    border: solid 1px #006FCF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tryAgainText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #006FCF;
}

.needHelpText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #006FCF;
    margin-top: 64px;
    cursor: pointer;
}

.logo {
    display: block;
    margin-top: 6px;
    margin-left: 400px;
}

.exclaimIcon {
    margin: 100px auto 0;
    width: 64px;
    height: 64px;
}

.footer {
    width: 100%;
    background-color: #f7f8f9;
    position: absolute;
    bottom: 0;
    flex-grow: 1;
}

@media only screen and (max-height: 650px) {
    .footer {
        position: absolute;
    }
}

@media only screen and (max-width: 800px) {
    .logo {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 6px 0 auto;
    }
}

@media only screen and (max-width: 500px) {

    .messageContainer {
        display: flex;
        align-content: center;
        flex-direction: column;
    }

    .footer {
        width: 100%;
        background-color: #f7f8f9;
        position: relative;
    }

    .logo {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 6px 0 auto;
    }

    .exclaimIcon {
        margin: 100px auto 0;
        width: 64px;
        height: 64px;
    }
}
