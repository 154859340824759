.modalBackdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51,51,51,0.65);
    display: flex;
}

.modal {
    opacity: 1;
    position: fixed;
    left: 0;
    top: 5%;
    right: 0;
    bottom: 0;
    display: table;
    align-items: stretch;
    width: 700px;
    background-color: #ffffff;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 4px;
}

.modalHeader {
    background-color: #006fcf;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
}

.headerText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
}

.close {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
}

.modalContent {
    height: 250px;
    margin-left: 40px;
    margin-top: 30px;
    margin-right: 40px;
    align-content: flex-start;
    text-align: left;
    overflow-y: scroll;
}

.modalContent::-webkit-scrollbar {
    width: 5px;
}

.modalContent::-webkit-scrollbar-thumb {
    /* width: 8px; */
    height: 50px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.51);
}

.modalContent::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.39);
}

.modalContent::-webkit-scrollbar-track {
    width: 16px;
    /* height: 811px;
    margin: 108px 0 0 358px;
    padding: 310px 4px 4px; */
    box-shadow: inset -0.5px 0 0 0 rgba(196, 23, 23, 0.07), inset -1px 0 0 0 rgba(0, 0, 0, 0.02), inset 1px 0 0 0 rgba(0, 0, 0, 0.02), inset 0.5px 0 0 0 rgba(0, 0, 0, 0.07);
    background-color: rgba(0, 0, 0, 0.02);
}

.heading {
    font-family: 'BentonSans Regular';
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #333333;
}

.basicText {
    margin-top: 16px;
    margin-bottom: 20px;
    max-width: 604px;
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #333333;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #ecedee;
}

.modalFooter {
    height: 50px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 10px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    text-align: left;
}

.sendButton {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
}

.sendButton2 {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    background-color: #bfd2e2;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
}

.sendButtonText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.verticalCenter {
    display: flex;
    align-content: center;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.sendButton:hover {
    background: #0061b6;
}

.addButton {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
}

.addButton:hover {
    background: #0061b6;
}

.addButtonText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.emails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.removeGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.removeButton {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    background-color: #cc0000;
    text-align: center;
    vertical-align: bottom;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: 5px;
}

.andOr {
    margin-top: 10px;
    font-size: 16px;
    color: #53565a;
    font-weight: bold;
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .modal {
        width: 382px;
    }
    .heading {
        font-size: 20px;
    }
    .removeGroup {
        width: 100%;
    }
}