.container {
    display: flex;
    padding-top: 24px;
    padding-left: 32px;
}

.documentDetailsContainer {
    width: 680px;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 27px;
}

.documentContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 6px;
}

.documentCheckbox {
    padding-right: 29px;
}

.documentInfoItem {
    display: flex;
    flex: 50%;
    flex-direction: column;
    width: 100%;
}

.total {
    display: flex;
    align-items: center;
}

.totalLabel {
    font-family: "BentonSans Regular";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
}

.totalText {
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #008767;
    margin-left: 36px;
}

.label {
    font-family: "BentonSans Regular";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    padding-bottom: 8px;
}

.details {
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
}

.email_list {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "BentonSans Regular";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 13px;
    position: relative;
    top: 3px;
}

.button {
    color: #8f9396;
    text-decoration: underline;
    cursor: pointer;
}

.preview {
    position: relative;
    cursor: pointer;
    padding: 4px;
    height: 19px
}

.display {
    display: flex;
}

.tooltipContainer {
    position: relative;
  }
  
  .Tooltip {
    width: 68px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    position: absolute;
    /* float: right; */
    /* top: 460px;
    left: 1181px; */
    right: -47px;
    bottom: 32px;
    padding: 10px;
    text-align: center;
    color: black;
    font-size: 12px;
  }
  
  .Tooltip::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

@media only screen and (max-width: 850px) {
    .documentDetailsContainer {
        flex-direction: column;
        padding-bottom: 8px;
    }

    .documentInfoItem {
        padding-bottom: 24px;
    }

    .total {
        flex-direction: column;
        align-items: flex-start;
        justify-items: center;
    }


    .totalText {
        margin-left: 0px;
    }

    .documentContainer {
        padding-bottom: 4px;
    }

}