.title {
  width: 124px;
  height: 22px;
  margin: 0 164px 8px 0;
  font-family: "BentonSans Regular";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #53565a;
}

.label {
  width: 240px;
  height: 24px;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #53565a;
  margin-left: 5px;
}

.checkboxComponent {
  width: 288px;
  padding-top: 0;
  padding-right: 16px;
  padding-bottom: 0;
  padding-left: 0;
}

.checkBoxContainer {
  /* height: 100px; */
  width: 288px;
  /* overflow-y: auto; */
}

.Checkbox {
  width: 17px;
  height: 17px;
  vertical-align: -16%;
}

.sectionLine {
  width: 110%;
  height: 1px;
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: 0;
  background-color: #ecedee;
}

@media (min-width: 320px) and (max-width:480px) {
  .Checkbox {
    width: 22px;
    height: 22px;
  }

  .label {
    font-size: 16px;
  }
}