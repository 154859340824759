.container {
    width: 609px;
    margin: 32px 20px 32px 40px;
    height: 60px;
    overflow-y: scroll;
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .container {
        width: auto;
    }
}