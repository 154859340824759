.header {
    width: 100%;
    height: 30px;
    font-family: "BentonSans Regular";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #006fcf;
}

.text {
    width: 239.9px;
    height: 24px;
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
}