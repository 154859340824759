.modal {
    font-family: "BentonSans Regular";
    width: 47%;
    position: fixed;
    left: 48%;
    top: 24%;
    opacity: 1;
    align-items: stretch;
    margin: 0 auto;
    /* padding: 10px; */
    z-index: 3;
    border-color: black;
    border-style: solid;
    border-width: 1px;
}

.container {
    font-family: "BentonSans Regular";
    display: block;
    background-color: white;
    line-height: 30px;
    padding: 12px;
    height: 300px;
    overflow-y: auto;
}

.header {
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #006fcf;
    color: white;
    font-size: 16px;
}

.body {
    font-family: "BentonSans Regular";
    font-size: 11px;
    line-height: 15px;
    padding-bottom: 10px;
    height: 100%;
}

li {
    padding: 3px;
    line-height: 1.3rem;
    font-size: 11px;
}

.button {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    background-color: #006fcf;
}

.separator {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #ecedee;
    align-self: flex-end;
}

.addButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    cursor: pointer;
    color: white;
}

.disabledButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #959798;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    cursor: no-drop;
    color: white;
}

.title {
    padding-top: 4px;
    font-size: 14px;
}

.inputContainer {
    width: 250px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #53565a;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.inputContainerError {
    width: 250px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px red;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.inputContainerWarning {
    width: 250px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #ffbf00;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.inputField {
    width: 85%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    background: transparent;
    outline: 0;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}

.inputFieldError {
    display: flex;
    width: 200px;
    height: 25px;
    border: solid 1.5px red;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-bottom-style: solid;
    border-radius: 4px;
}

.inputText {
    width: 246px;
}

.errorText {
    font-size: 12.3px;
    color: red;
    left: 288px;
    position: absolute;
    top: 70px;
}

.errorTextWarning {
    font-size: 12.3px;
    color: #ffbf00;
    left: 288px;
    position: absolute;
    top: 70px;
}

.lang {
    margin-left: 3px;
}

.list {
    list-style-type: circle;
    width: auto;
}

/* @media only screen and (min-width: 300px) and (max-width: 850px) {
    .inputContainer {
        width: auto;
    }

    .inputField {
        width: auto;
    }

    .inputContainerError {
        width: auto;
    }
}

@media only screen and (max-width: 2560px) {
    .m1 {
        left: 19%;
        position: relative;
    }
}

@media only screen and (max-width: 1920px) {
    .m1 {
        left: 19%;
        position: relative;
    }
}

@media only screen and (max-width: 1536px) {
    .m1 {
        left: 19%;
        position: relative;
    }
}

@media only screen and (max-width: 1280px) {
    .m1 {
        left: 9%;
        position: relative;
    }
}

@media only screen and (max-width: 1024px) {
    .m1 {
        left: -7%;
        position: relative;
    }
} */