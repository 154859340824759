.flexbox {
  display: flex;
}

.title {
  width: 57px;
  height: 22px;
  margin-top: 20px;
  margin-right: 164px;
  margin-bottom: 8px;
  margin-left: 0;
  font-family: "BentonSans Regular";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #53565a;
}

.minEntry {
  width: 151px;
  height: 44px;
  padding: 1px 0 0 1px;
  background-color: #ffffff;
}

.maxEntry {
  width: 151px;
  height: 44px;
  padding: 1px 0 0 1px;
  background-color: #ffffff;
}

.minLabel {
  width: 72px;
  height: 15px;
  margin: 0 70px 3px 7px;
  font-family: "BentonSans Regular";
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.maxLabel {
  width: 74px;
  height: 15px;
  margin: 0 58px 3px 7px;
  font-family: "BentonSans Regular";
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.minValue {
  width: 81px;
  height: 21px;
  margin: 3px 61px 3px 7px;
  font-family: "BentonSans Regular";
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

.maxValue {
  width: 81px;
  height: 21px;
  margin: 3px 43px 3px 7px;
  font-family: "BentonSans Regular";
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #53565a;
}

input[type=text] {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
}

input:focus {
  outline: none;
}

.errorMsg {
  width: 155px;
  height: 22px;
  margin: 15px 5px 25px 0;
  font-family: "BentonSans Regular";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #b42c01;
}

.maxLine {
  box-sizing: border-box;
  width: 135px;
  height: 1px;
  margin: 0 0 0 0;
  border: solid 0.5px #53565a;
  background-color: #53565a;
}

.minLine {
  box-sizing: border-box;
  width: 125px;
  height: 1px;
  margin: 0 0 0 0;
  border: solid 0.5px #53565a;
  background-color: #53565a;
}


.sectionLine {
  width: 110%;
  height: 1px;
  margin-top: 30px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: 0;
  background-color: #ecedee;
}

@media (min-width: 481px) and (max-width:1024px) {
  .minEntry {
    width: 121px;
  }

  .maxEntry {
    width: 121px;
  }

  .minLabel {
    margin: 0;
  }

  .maxLabel {
    margin: 0;
  }

  .maxLine {
    width: 115px;
  }

  .minLine {
    width: 105px;
  }
}