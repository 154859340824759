table.searchTable {
    font-family: "BentonSans Regular";
    border-collapse: collapse;
    width: 78%;
    margin-left: auto;
    margin-right: auto;
}

td,
th {
    border: 1px solid black;
    text-align: center;
    padding: 5px;
}