.SidebarTextActive {
    width: 100%;
    height: 22px;
    font-family: "BentonSans Regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #00175a;
    margin-top: 16px;
}

.SidebarTabInactive {
    width: 100%;
    height: 60px;
    border: solid 1px #ecedee;
    background-color: #f7f8f9;
    text-align: center;
}

.SidebarTabTextInactive {
    width: 100%;
    height: 22px;
    font-family: "BentonSans Regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #006fcf;
    margin-top: 19px;
}