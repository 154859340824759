.selected {
    background-color: #00175a;
}

.selectedText {
    min-width: 65%;
    font-family: 'BentonSans Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
}

.iconContainer {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}

.container {
    min-height: 45px;
    font-family: 'BentonSans Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 10px;
}

.container:not(.selected):hover {
    background-color: #f7f8f9;
}