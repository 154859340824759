* {
    scroll-behavior: smooth;
}

.container {
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 8px;
}

/*This is here so the footer doesn't overlap the main content*/
.pageSpacer {
    height: 179px;
}

.border {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #152835;
    font-family: "BentonSans Regular";
    margin-bottom: 10px;
    padding: 4px 16px 4px 16px;
    font-weight: 600;
    border: 1px solid #a7a8aa;
    border-bottom: double;
    background-color: white;
    height: 20px;
}

.pagination {
    font-family: "BentonSans Regular";
    float: right;
    font-size: 12px;
    display: flex;
}

.pagination button {
    color: #152835;
    font-weight: 600;
    float: left;
    padding: 4px 7.5px;
    text-decoration: none;
    cursor: pointer;
}

.pagination button {
    border-radius: 12px;
    font-size: 12px;
}

.active {
    background-color: #a7a6a6;
    border-radius: 12px;
    color: black;
}

.notActive {
    color: #7F7F7F;
}

.footerToggle {
    display: flex;
    /* justify-content: center; */
    /* height: 16px; */
    width: 32px;
    cursor: pointer;
    /* border: 2px solid yellowgreen; */
    margin-left: auto;
    margin-right: auto;
}

.arrowDown {
    width: 0px;
    height: 0px;
    border-top: 6px solid #006fcf;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    display: inline-block;
    align-self: center;
    justify-self: flex-end;
}

.arrowUp {
    width: 0px;
    height: 0px;
    border-bottom: 6px solid #006fcf;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    display: inline-block;
    align-self: center;
    justify-self: flex-end;
}

.hideMoveupContainer {
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s;
    /* transition: 0.2s; */
}

.moveupContainer {
    visibility: visible;
    transition: all 0.1s;
    /* transition: 0.2s; */
    opacity: 1;

    width: 120px;
    height: 70px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #006fcf;
    margin-bottom: 30px;
    margin-right: 20px;
    padding: 3px;
    font-size: 0.9em;
    cursor: pointer;
}

.moveupCircle {
    width: 45px;
    height: 45px;
    background-color: #006fcf;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.moveupCircle:hover {
    opacity: 0.5;
}

.chevronup {
    margin-top: 5px;
    width: 30%;
    height: 30%;
    border-top: 5px solid white;
    border-left: 5px solid white;
    transform: rotate(45deg);
    background-color: #006fcf;
}

.moveupText {
    background-color: white;
    color: #006fcf;
}