.container {
    position: absolute;
    width: 400px;
    height: 28%;
    right: 1%;
    top: 10%;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    color: #006fcf;
    z-index: 4;
}

.containermobile {
    position: absolute;
    width: 200px;
    height: 28%;
    right: 8%;
    top: 10%;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    color: #006fcf;
}

ul {
    list-style-type: none;
    font-size: 14px;
    flex-direction: row;
    width: 40px;
}

.container li {
    width: 70px;
    padding:8px;
    margin-left: 10px;
    font-size: 16px;
    list-style-type:none;
    border-bottom: solid 1px #c8c9c7;
    float: left;
}

.list {
    height: 40px;
}

.selected {
    background-color: #00175a;
}

.container::after {
    display: flex;
    flex-wrap: wrap;
    width: 0px;
    height: 0px;
    content: "";
    display: inline-block;
    top: -8px;
    left: 75%;
    position: absolute;
    margin-right: 16px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
}

.container::before {
    display: flex;
    flex-wrap: wrap;
    width: 0px;
    height: 0px;
    content: "";
    display: inline-block;
    top: -8px;
    left: 75%;
    position: absolute;
    margin-right: 16px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 8px solid #c8c9c7;
}
