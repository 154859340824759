.container {
    width: 139px;
    height: 44px;
    margin-right: 15px;
    background-color: #ffffff;
}

.fieldContainer {
    width: 169px;
    height: 44px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #2F528F;
}

.labelText {
    width: 85px;
    height: 15px;
    font-family: "BentonSans Regular";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    padding-left: 2px;
}

.standaloneDateField {
    box-sizing: border-box;
    border: none;
    background: transparent;
    outline: 0;
    font-family: "BentonSans Regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 85%;
    caret-color: transparent;
    cursor: pointer;
    color: #97999b;
    display: inline-block;
    text-shadow: 0 0 0 black;
    padding-top: 11px;
}

.dateField {
    box-sizing: border-box;
    border: none;
    background: transparent;
    outline: 0;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 80%;
    caret-color: transparent;
    cursor: pointer;
    color: transparent;
    display: inline-block;
    text-shadow: 0 0 0 black;
    padding-top: 11px;
}

.dateField::placeholder {
    font-family: "BentonSans Regular";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #97999b;
}

.line {
    box-sizing: border-box;
    width: 139px;
    height: 1px;
    border: solid 1px #53565a;
    background-color: #53565a;
}

.icon::before {
    font-family: "dls-icons-2.16.0";
    content: "";
    color: #006fcf;
    font-size: 1.375rem;
    font-weight: 500;
    float: right;
    padding-top: 11px;
}

.icon:hover:before {
    content: "";
}
