.container {
    position: absolute;
    width: 290px;
    height: 407px;
    right: 10%;
    top: 115%;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    z-index: 1;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #c8c9c7;
}

.button {
    width: 110px;
    height: 40px;
    border-radius: 4px;
    background-color: #006fcf;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
}

.button:hover {
    background: #0061b6;
}

.button:active {
    background: #00355f;
}

.buttonText {
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.clearText {
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: 1000;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #006fcf;
    width: 30%;
}

.headerText {
    font-family: 'BentonSans Regular';
    font-size: 16px;
    font-weight: 1000;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;

}

.header {
    height: 15%;
    margin-left: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.list {
    height: auto;
}

.selected {
    background-color: #00175a;
}

.sortItem {
    min-height: 45px;
    font-family: 'BentonSans Regular';
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    display: flex;
    justify-items: center;
    align-items: center;
}

.footer {
    width: 100%;
    height: 15%;
    margin-left: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.footerRight {
    width: 60%;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
}

.container::after {
    width: 0px;
    height: 0px;
    content: "";
    display: inline-block;
    top: -8px;
    left: 75%;
    position: absolute;
    margin-right: 16px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
}

.container::before {
    width: 0px;
    height: 0px;
    content: "";
    display: inline-block;
    top: -8px;
    left: 75%;
    position: absolute;
    margin-right: 16px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 8px solid #c8c9c7;
}