.backDrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51,51,51,0.65);
    display: flex;
}

.supportModal {
    width: 50%;
    height: 40%;
    position: fixed;
    top: 25%;
    left: 25%;
    opacity: 1;
    display: table;
    align-items: stretch;
    flex-direction: column;
    margin: 0 auto;
}

.supportModal div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* border: 8px solid transparent; */
    background-color: white;
}

#emailTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #emailTable td, #emailTable th {
    border: 1px solid #ddd;
    padding: 8px;
    font-family: "BentonSans Regular";
    /* font-size: 15px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: center;   */
  } 
  
  #emailTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #006fcf;
    color: white;
  }

  .m1 {
    position: relative;
  }

  /* .xyz {
    position: absolute;
    right:-10px;
    top:-10px;
    color: white;
    font-size: 20px;
  } */

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
  }

  .emailIcon {
    /* height: 150%; */
    width: 6%;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-top: 0px;
    position: relative;
    top: 5.5px;
    left: 8px;
    cursor: pointer;
  }

  .emailContainer {
    display: block;
    height: 100%;
    width: 100%;
    /* background-color: #ccc; */
    line-height:40px;
    padding: 0px;
    border-bottom: 1px solid #ddd;
  }

.emailText {
  font-size: 14px;
  text-align: center;
}

.emailContainer:hover {
  background-color: #D3D3D3;
}