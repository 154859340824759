.background {
    /* width: 721px; */
    max-width:95%;
    min-height: 353px;
    border-radius: 8px;
    /* margin-left: 32px; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    background-color: #ffffff;
}

.scrollable {
    height: 78%;
    overflow: scroll;
}

@media only screen and (max-width: 1050px) {

    .background {
        width: 616px;
        height: auto;
        margin-left: 16px;
    }

}

@media only screen and (max-width: 500px) {
    .background {
        width: 374px;
        height: auto;
        margin-right: auto;
        margin-left: auto;
        border-radius: 8px;
        background-color: #ffffff;
        display: block;
        overflow: hidden;
    }
}

