.header {
    width: 100%;
    height: 30px;
    font-family: "BentonSans Regular";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #b42c01;
}