.backDrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.65);
    display: flex;
}

.modal {
    font-family: "BentonSans Regular";
    width: 50%;
    height: 40%;
    position: fixed;
    top: 25%;
    left: 25%;
    opacity: 1;
    display: block;
    align-items: stretch;
    flex-direction: column;
    margin: 0 auto;
    padding: 10px;
}

.container {
    font-family: "BentonSans Regular";
    display: block;
    height: 100%;
    background-color: white;
    line-height: 15px;
    padding: 12px;
}

.header {
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #006fcf;
    color: white;
    font-size: 16px;
}

.body {
    font-family: "BentonSans Regular";
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
}

.m1 {
    width: 73%;
    left: 21%;
    position: relative;
}

.button {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.addButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    cursor: pointer;
    color: white;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    background-color: #006fcf;
}

/* .separator {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #ecedee;
    align-self: flex-end;
} */

/* .addButton {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    background-color: #006fcf;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    cursor: pointer;
    color: white;
} */

.email_list {
    align-items: center;
    font-family: "BentonSans Regular";
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 13px;
    position: relative;
    top: 3px;
    color: rgb(162, 157, 157);
    line-height: 1.4em;
}

.email_list_lowercase{
    text-transform: lowercase;
}

.assistant_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
}