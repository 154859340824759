html {
    background-color: #fff
}

body {
    background-color: #fff;
    display: grid;
}

.container {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #fff
}

.topSpacer {
    position: sticky;
    top: 0px;
    left: 0%;
    /* padding-top: 8px; */
    background-color: #fff;
    z-index: 1;
}

.spacingDiv {
    height: inherit;
}

.searchControls {
    position: sticky;
    top: 0%;
    left: 0%;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #a7a8aa;
}

.searchOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #006fcf;
    border: 1px solid #2F528F;
    border-radius: 8px;
    padding: 8px;
}

.dateSelector {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: "BentonSans Regular";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #152835;
    margin: 2px;
}

.andOrSelector {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "BentonSans Regular";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #152835;
}

.quickSearch {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.to {
    margin-left: 4px;
    margin-right: 4px;
}

.datePickerContainer {
    position: absolute;
    top: 120%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    color: black;
    z-index: 1;
    line-height: 15px;
}

.checkboxComponent {
    width: 288px;
    padding-top: 0;
    padding-right: 16px;
    padding-bottom: 0;
    padding-left: 0;
}

.checkBoxContainer {
    /* height: 100px; */
    width: 288px;
    /* overflow-y: auto; */
}

.Checkbox {
    width: 17px;
    height: 17px;
    border: 1px solid #152835;
    vertical-align: -16%;
}

.selectAndDownload {
    display: flex;
    justify-content: space-between;
    font-family: "BentonSans Regular";
    width: 99%;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #152835;
    padding: 4px 8px;
}

.selectAll {
    display:flex;
    /* flex: 1; */
    align-items: center;
}

.selectAllText {
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.selectAllBox{
    width: 280px;
    padding-left: 10px;
}

.download {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: "BentonSans Regular";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #4472CF;
    width: 389px;
}

.pageSelectorContainer {
    width: 257px;
    /* margin-right: 159px; */
}

.pageSelector {
    position: relative;
    width: 59px;
    height: 35px;
    padding-right: 3px;
    padding-left: 3px;
    border-radius: 0;
    color: #97999b;
}

.pageSelector:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1) transparent transparent transparent;
}

.downloadDisable {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: "BentonSans Regular";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #a5a5a5;
    width: 393px;
    cursor: default;
}

.downloadDisable div[role="button"] .downloadButtons {
    cursor:default;
}

input[type="radio"] {
    margin: 0px;
    padding-left: 4px;
    padding-right: 4px;
}

label {
    padding-left: 4px;
    padding-right: 4px;
}

.field {
    font-family: "BentonSans Regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    width: 310px;
    height: 44px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 4px;
    border: solid 1px #2F528F !important;
    color: #97999b;
}

.documentContainer {
    margin-top: 8px;
    padding-bottom: 36px;
}

.clearIcon {
    width: 32px;
    height: 32px;
    padding: 8px;
    cursor: pointer;
}

.infoIcon {
    z-index: 1;
    margin-left: -49px;
    width: 32px;
    height: 32px;
    padding: 8px;
    cursor: pointer;
}

.downloadButtons {
    vertical-align: 'center';
    display: 'flex';
    align-items: 'center';
    font-weight: bold;
}

.reportAll {
    font-family: "BentonSans Regular";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #4472CF;
    vertical-align: 'center';
    display: 'flex';
    align-items: 'center';
    font-weight: bold;
}