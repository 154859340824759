.container {
    min-height: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.container embed html {
    height: 100%;
}

.pdfFrame {
    width: 100%;
    height: 100%;
}