.container {
    height: 80%;
    width: 80%;
    max-width: 100rem;
    min-width: 20rem;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 10%;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.content {
    overflow-y: scroll;
    display: block;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51,51,51,0.65);
    display: flex;
}

.header {
    height: 40px;
    padding: 1rem;
    border-bottom: 1px solid #D4D4D4;
    border-bottom-width: 2px;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
}

.headerText {
    font-family: 'BentonSans Regular';
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #00175a;
    text-align: center;
}

.country {
    width: 200px;
    height: 18px;
    font-family: 'BentonSans Regular';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #00175a;
    padding-left: 40px;
}

.bottomLine {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}

.label {
    width: 102px;
    height: 18px;
    font-family: 'BentonSans Regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
    padding-left: 40px;
}

.email {
    width: 123px;
    height: 18px;
    font-family: 'BentonSans Regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
    color: #006fcf;
    padding-right: 20px;
}

.phone {
    width: 136px;
    height: 18px;
    font-family: 'BentonSans Regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
    color: #006fcf;
    padding-right: 20px;
}

.groupHeader {
    font-family: 'BentonSans Regular';
    margin-left: 40px;
}

.close {
    position: absolute;
    right: 2em;
    top: 0em;
    width: 24px;
    height: 24px;
    object-fit: contain;
    color: #006fcf;
}

.closeSvg {
    width: 64px;
    height: 64px;
    transform: scale(.5);
    object-fit: contain;
    fill: #006fcf;
    top: 0;
}