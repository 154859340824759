.container {
    width: 15px;
    height: 15px;
    border: 1px solid #152835;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
}

.containerChecked {
    width: 15px;
    height: 15px;
    border: 1px solid #152835;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
}

.checkmark {
    display: flex;
    justify-content: center;
    align-content: center;
}

.containerChecked .checkmark:after {
    display: inline-block;
    content: '';
    left: 0px;
    top: 0px;
    width: 4px;
    height: 10px;
    border: solid #006fcf;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
}

.container input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.containerChecked input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}