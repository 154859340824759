.dropDown {
    min-width: 39px;
    height: 21px;
    margin: 1px 3px 3px 8px;
    font-family: "BentonSans Regular";
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: black;
    border-style: none;
    outline: none;
    margin-left: 0;
    /* appearance: none; */
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13.5px;
}

.dropDownTopUser {
    min-width: 39px;
    height: 21px;
    margin: 1px 13px;
    font-family: "BentonSans Regular";
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: black;
    border-style: none;
    outline: none;
    margin-left: 0;
    /* appearance: none; */
    cursor: pointer;
    font-weight: 600;
    font-size: 13.5px;
    float: right;
}


.sectionLine {
    width: 110%;
    height: 1px;
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 12px;
    margin-left: 0;
    background-color: #ecedee;
}

.dropdownLine {
    width: 290px;
    height: 0px;
    margin: 0 0 0 3px;
}

@media (min-width: 481px) and (max-width:1024px) {
    .dropdownLine {
        width: 235px;
    }

    .dropDown {
        min-width: 39px;
    }
}

@media (min-width: 320px) and (max-width:480px) {
    .dropdownLine {
        width: 265px;
    }

    .dropDown {
        min-width: 39px;
    }
}