.container {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;
    column-gap: 20px;
}

.description {
    width: 100%;
    height: 21px;
    font-family: "BentonSans Regular";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 2; */
    letter-spacing: normal;
    color: #333333;
}

.description2 {
    width: 100%;
    height: 21px;
    font-family: "BentonSans Regular";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #333333;
    margin-top: 70px;
    margin-bottom: 20px;
    display: flex;
}

.searchError {
    width: 100%;
    height: 22px;
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #b42c01;
    margin-top: 16px;
}

.searchBox {
    width: 310px;
    height: 44px;
    border-radius: 4px;
    border: solid 1px #53565a;
    background-color: #ffffff;
    /* display: flex;
    flex-direction: row; */
    margin-top: 16px;
    margin-bottom: 20px;
}

.searchField {
    width: 85%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    background: transparent;
    outline: 0;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

.searchField::placeholder {
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #97999b;
}

.searchIcon {
    width: 50px;
    height: 44px;
    border-radius: 0px 4px 4px 0px;
    box-sizing: border-box;
    border: solid 1px #006fcf;
    background-color: #006fcf;
    display: flex;
    margin: 10px;
}

.searchIcon:hover {
    background: #0061b6;
}

.searchIcon:active {
    background: #00355f;
}

.dateFieldsContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.searchButton {
    margin-top: 16px;
    width: 310px;
    height: 40px;
    border-radius: 4px;
    background-color: #006fcf;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.liner {
    width: 110%;
    height: 1px;
    margin-top: 20px;
    /* margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0; */
    background-color: #ecedee;
  }

  .nameLiner {
      font-size: 20px;
      margin-left: 8px;
      margin-right: 8px;
      color:#006fcf;
  }
  

.searchButton:hover {
    background: #0061b6;
}

.searchButton:active {
    background: #00355f;
}

.dlsIconSearch {
    display: flex;
    font-family: "dls-icons-2.16.0";
    width: 100%;
    height: 100%;
}

.dlsIconSearch::before {
    display: flex;
    font-size: 1.375rem;
    font-family: "dls-icons-2.16.0";
    content: "";
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
}

.dlsIconSearch.icon-hover:hover::before {
    content: "";
}

.dateError {
    height: 22px;
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #b42c01;
    margin-top: 12px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 500px) {
    .background {
        width: 374px;
        height: auto;
        margin-right: auto;
        margin-left: auto;
        border-radius: 8px;
        background-color: #ffffff;
        display: block;
        overflow: hidden;
    }
}