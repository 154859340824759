.HeaderTab {
    width: 200px;
    height: 64px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
  }

  .LinkLine1 {
    width: 100%;
    height: 17px;
    font-family: "BentonSans Regular";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 18px;
    color: #00175a;
    display: flex;
    /* justify-content: center; */
    align-self: center;
    align-items: center;
    margin-left: 23px;
  }

  .StyleIconsUniversalInvoiceinvoice_active {
    width: 24px;
    height: 24px;
    margin-right: 3px;
    object-fit: contain;
    justify-content: center;
    align-self: center;
  }

 