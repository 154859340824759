.name {
    float: left;
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #333333;
    flex-grow: 4;
    text-align: left;
}

.close {
    float: right;
    position: relative;
    top: 50%;
}

.date {
    float: right;
    font-family: 'BentonSans Regular';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #333333;
    margin-right: 18px;
}

.container {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .container {
        flex-direction: column;
        align-items: flex-start;
    }
    .close {
        display: none;
    }
    .name {
        margin-bottom: 9px;
    }
}