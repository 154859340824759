.hidden {
  display: none
}

.description {
  /* width: 519px; */
  height: 42px;
  margin-top: 48px 0 0;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #53565a;
  padding: 15px;
}

.title {
  height: 60px;
  font-family: "BentonSans Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  padding-top: 150px;
  padding-bottom: 500px;
}

.circle {
  border: 4px solid #00175a;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  z-index: 86;
  margin-inline-start: 320px;
  margin-bottom: 8px;
}

.circle.filled {
  background-color: #f2f3f5;
}

.circle h1 {
  text-align: center;
  font-family: 'BentonSans Regular';
  font-weight: 600;
  font-size: 32px;
  color: #00175a;
  text-decoration: none;
  display: block;
  /*     letter-spacing: -0.02em; */
  /*     word-spacing: -0.02em; */
  line-height: 1;
  margin-top: 7px;
}

.opentextURL2 {
  display: inline-block;
  font-style: italic;
  font-size: 14px;
  color: #53565a;
  text-decoration: underline;
  /* border: 2px solid yellow; */
  padding: 2px;
  margin-left: 0px;
  margin-right: 0px;
}

.redirect_msg3 {
  margin-left: 0.5px;
}

.redirect_msg1 {
  margin-right: -2.5px;
}

.email_list {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "BentonSans Regular";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 13px;
  position: relative;
  top: 3px;
}

.button {
  color: #8f9396;
  text-decoration: underline;
  cursor: pointer;
}