.container {
    display: flex;
    height: 23px;
    width: 5px;
    margin-right: 5px;
}

.popupcontainer {
    margin-left: 7px;
    position: relative;
    height: fit-content;
    top: 31%;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    color: #595959;
    z-index: 1;
}

.popupcontainer li {
    list-style-type: none;
    font-size: 14px;
    padding: 5px;
}

.popupcontainer::before {
    display: inline-block;
    position: absolute;
    width: 0px;
    height: 0px;
    content: "";
    top: 5px;
    left: -3%;
    margin-right: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 5px solid #c8c9c7;
}

.popupcontainer::after {
    display: inline-block;
    position: absolute;
    width: 0px;
    height: 0px;
    content: "";
    top: 5px;
    left: -2%;
    margin-right: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 5px solid #ffffff;
}

.scrollableList {
    width: max-content;
    max-height: 150px;
    overflow: scroll;
}

.popupheading {
    white-space: nowrap;
    font-size: 11px;
    position: relative;
    text-align: center;
    height: 20px;
    border-radius: 4px;
    border: solid 1px #c8c9c7;
    background-color: #ffffff;
    /* left: 5%; */
    margin-left: 7px;
    padding: 3px;
}

.popupheading::before {
    display: inline-block;
    position: absolute;
    width: 0px;
    height: 0px;
    content: "";
    top: 5px;
    left: -6%;
    margin-right: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 5px solid #c8c9c7;
}

.popupheading::after {
    display: inline-block;
    position: absolute;
    width: 0px;
    height: 0px;
    content: "";
    top: 5px;
    left: -5%;
    margin-right: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 5px solid #ffffff;
}