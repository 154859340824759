.filterButton {
    margin-top: 16px;
    width: 158px;
    height: 40px;
    border-radius: 4px;
    background-color: #006fcf;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 37px 0 0;
}

.filterButton:hover {
    background: #0061b6;
}

.btnLabel {
    width: 90%;
    height: 22px;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.scroller {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
    width: 110%;
    padding: 20px;
    border-style: none;
    border-color: brown;
    box-sizing: border-box;
    /* margin-left:-30px; */
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 150px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.51);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.39);
}

::-webkit-scrollbar-track {
    width: 16px;
    height: 811px;
    margin: auto;
    padding: 310px 4px 4px;
    box-shadow: inset -0.5px 0 0 0 rgba(196, 23, 23, 0.07), inset -1px 0 0 0 rgba(0, 0, 0, 0.02), inset 1px 0 0 0 rgba(0, 0, 0, 0.02), inset 0.5px 0 0 0 rgba(0, 0, 0, 0.07);
    background-color: rgba(0, 0, 0, 0.02);
}


@media (min-width: 320px) and (max-width:480px) {
    .filterButton {
        width: 230px;
        margin-left: 15px;
    }
}

@media (min-width: 481px) and (max-width:1024px) {
    .filterButton {
        width: 210px;
        margin: auto;
    }

    .scroller {
        width: 115%;
    }
}