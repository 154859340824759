.title {
  width: 68%;
  height: 40px;
  margin-top: 12px;
  margin-right: 33px;
  margin-bottom: 0;
  margin-left: 0;
  font-family: "BentonSans Regular";
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
}

.filtersApplied {
  width: 80%;
  height: 9px;
  margin-top: 0;
  margin-right: 104px;
  margin-bottom: 24px;
  margin-left: 0;
  font-family: "BentonSans Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #53565a;
}

.sectionLine {
  width: 110%;
  height: 1px;
  margin-top: 12px;
  margin-right: 0;
  margin-bottom: 12px;
  /* margin-left: -40px; */
  background-color: #ecedee;
}

.clearFilter {
  width: 32%;
  height: 37px;
  font-family: "BentonSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #006fcf;
  margin-top: 16px;
}

.flexBox {
  display: flex;
}

@media (min-width: 481px) and (max-width:1024px) {
  .title {
    font-size: 15px;
  }

  .clearFilter {
    font-size: 11px;
    margin-top: 16.5px;

  }

  .filtersApplied {
    font-size: 10px;
  }
}

@media (min-width: 320px) and (max-width:480px) {
  .clearFilter {
    margin-top: 18.5px;
  }
}