@font-face {
    font-family: 'BentonSans Regular';
    src: local('BentonSans Regular'), url(../../fonts/BentonSans-Regular.woff) format('woff');
}

.header {
    font-family: "BentonSans Regular";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #006fcf;
}

.text {
    font-family: "BentonSans Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
}