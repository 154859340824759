.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    /* background-color: white; */
    background-color: #E6F0FA;
    font-family: "BentonSans Regular";
    font-weight: 600;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-top: 8px;
    color: #152835;
    border: 1px solid #a7a8aa;
}

.documentNumber {
    color: #006fcf;
    margin-left: 4px;
}

.nameWrap {
    /* flex: 1; */
    color: #006fcf;
    margin-left: 4px;
    flex-grow: 1;
}

.labelcontainer {
    display: flex;
    /* flex-wrap: wrap; */
    /* width: 406px; */
}

.label {
    white-space: nowrap;
}

.inline {
    display: flex;
    margin-top: 3px;
}

.nameLabel{
   margin-left: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.column {
    /* width: 381px; */
    width: 29vw;
}

.Checkbox {
    margin-left: 4px;
    margin-bottom: 3px;
}

.multiuserContainer {
    display: flex;
}

.multiuser {
    display: flex;
    justify-content: space-between;
    width: 82%;
}