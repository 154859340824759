
.datePicker {
    max-width: 36.65rem;
    margin-top: 20px;
    position: relative;
    font-family: 'BentonSans Regular';
}

.datePicker .formControl.iconHover {
    display: flex;
    border: none;
    height: 3.125rem;
    max-width: 18.625rem;
    min-width: 15.6875rem;
    padding: 0;
    position: relative;
}

.datePicker .formControl.iconHover input {
    background: transparent;
    padding: 0.625rem;
    width: 100%;
}

.datePicker .formControl.iconHover input:active, .datePicker .formControl.iconHover input:focus {
    border-color: #006fcf;
}

.datePicker .formControl.iconHover input:active ~ button, .datePicker .formControl.iconHover input:focus ~ button {
    color: #006fcf;
    border-color: #006fcf;
    font-family: "dls-icons-2.16.0";
    content: "";
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.datePicker .formControl.iconHover button {
    background: #fff;
    border: 1px solid #c8c9c7;
    border-radius: 0 0.25rem 0.25rem 0;
    transition: border-color 0.25S ease-out;
    color: #006fcf;
}

.datePicker .hasWarning.formControl.iconHover input:active, .datePicker .hasWarning.formControl.iconHover input:focus {
    border-color: #b42c01;
}

.datePicker .hasWarning.formControl.iconHover input:active ~ button, .datePicker .hasWarning.formControl.iconHover input:focus ~ button {
    border-color: #b42c01;
}

.datePicker .hasWarning.formControl.iconHover button {
    border: 1px solid #b42c01;
}

.datePicker .calendar {
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0.3125rem;
    max-width: 18.75rem;
    position: relative;
}

.datePicker .glyph {
    color: #c8c9c7;
    padding: 0.625rem;
    position: absolute;
    top: 0.625rem;
}

.datePicker button.glyph:hover, .datePicker button.glyph:active, .datePicker button.glyph:focus {
    color: #006fcf;
}

.datePicker .calendar glyph:first-of-type {
    left: 0;
}

.datePicker .calendar button.glyph:last-of-type {
    right: 0;
}

caption {
    margin: 0 2.5rem;
    padding: 0.9375rem 0;
    position: relative;
    text-align: center;
    caption-side: top;
}

.datePicker .calendar table thead {
    background: none;
}

.datePicker .calendar table thead tr th {
    border: none;
    padding: 0.9375rem 0.625rem;
    text-align: center;
}

.datePicker .calendar table thead tr th abbr {
    border: none;
    color: #333;
    cursor: default;
    text-decoration: none;
}

.datePicker .calendar table tr {
    border: 0;
}

.datePicker table tbody tr td {
    border: 0;
    color: #333;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    text-align: center;
}

.datePicker table tbody tr td:empty {
    border: none;
}

.datePicker table tbody tr td button {
    height: 100%;
    width: 100%;
}

.datePicker table tbody tr td button:hover {
    background-color: #ecedee;
}

.datePicker table tbody tr td button.selected {
    background-color: #006fcf;
    color: #fff;
}

.datePicker table tbody tr td button.selected:hover {
    background-color: #006fcf;
}

.datePicker table tbody tr td button.error {
    background-color: rgba(180, 44, 1, 0.1);
    color: #b42c01;
}

.datePicker table tbody tr td button.error:hover {
    background-color: rgba(180, 44, 1, 0.1);
}

.datePicker table tbody tr td button.due {
    box-shadow: inset 0 0 0 1px #00175a;
    color: #00175a;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.375rem;
}

.datePicker table tbody tr td button.range {
    color: #333;
    background-color: rgba(0, 111, 207, 0.1);
}

.datePicker table tbody tr td button.today {
    border-radius: 4px;
    border: 1px solid #006fcf;
}

.datePicker table tbody tr td button.range:hover {
    background-color: rgba(0, 111, 207, 0.2);
}

.datePicker table tbody tr td button[disabled] {
    color: #97999b;
    cursor: not-allowed;
}

.datePicker table tbody tr td button[disabled]:hover {
    background-color: transparent;
}

.datePicker table tbody tr td[aria-selected=true] button {
    background-color: #006fcf;
    color: #fff;
}

.datePicker table tbody tr td[aria-selected=true] button:hover {
    background-color: #006fcf;
}

.datePicker table tbody tr td[aria-selected=true] .due {
    background-color: #00175a;
    color: #fff;
}

.datePicker table tbody tr td[aria-selected=true] .due:hover {
    background-color: #00175a;
}

.datePicker table tbody tr td[aria-invalid=true] button {
    background-color: rgba(180, 44, 1, 0.1);
    color: #b42c01;
}

.datePicker table tbody tr td[aria-invalid=true] button:hover {
    background-color: rgba(180, 44, 1, 0.1);
}

@media (min-width: 768px) {  .datePicker .range {
    max-width: 36.65rem;
}

    .datePicker .range table {
        display: inline;
    }
}

@media (max-width: 767px) {  .datePicker .range td:first-of-type {
    border-left: 0;
}
}

.datePicker .calendar:not(.range) td:first-of-type {
    border-left: 0;
}

.datePicker .calendarFooter {
    border-top: 1px solid #ecedee;
}

.datePicker .calendarFooter div {
    padding: 0.625rem;
}

.heading {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
}

.gray {
    color: #333 !important;
    fill: #333 !important;
}

.border {
    border: 0.0625rem solid #ecedee;
}

.dlsGlyphLeft {
    position: absolute;
    left: 0;
    top: .625rem;
    padding: .625rem;
}

.dlsGlyphRight {
    position: absolute;
    right: 0;
    top: .625rem;
    float: right;
    padding: .625rem;
}

.dlsGlyphLeft:before {
    font-family: "dls-icons-2.16.0";
    position: absolute;
    content: "";
}

.dlsGlyphRight:before {
    font-family: "dls-icons-2.16.0";
    position: absolute;
    content: "";
}

button {
    min-width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: 0;
    background-color: transparent;
    font-family: 'BentonSans Regular';
    text-align: center;
}

td button {
    width: 100%;
    height: 100%;
}

.glyph,
.icon {
    display: flex;
    justify-self: center;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}

.glyph:before {
    backface-visibility: hidden;
    display: block;
    font-family: "dls-icons-2.16.0";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
    position: relative;
    speak: none;
    vertical-align: middle;
}

.table {
    width: 100%;
}

.glyphLg, .glyphLg:before {
    font-size: 1rem;
}

.table thead th {
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #333;
}