.dropDownContainer {
    margin-top: 10px;
}

.label {
    width: 80%;
    height: 15px;
    margin: 0 205px 0 10px;
    font-family: "BentonSans Regular";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #97999b;
}

.dropDown {
    min-width: 288px;
    height: 21px;
    margin: 1px 3px 3px 8px;
    font-family: "BentonSans Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #97999b;
    border-style: none;
    outline: none;
}


.sectionLine {
    width: 110%;
    height: 1px;
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 12px;
    margin-left: 0;
    background-color: #ecedee;
}

.dropdownLine {
    width: 290px;
    height: 0px;
    margin: 0 0 0 3px;
    border-bottom: 2px dotted #97999b;
}

@media (min-width: 481px) and (max-width:1024px) {
    .dropdownLine {
        width: 235px;
    }

    .dropDown {
        min-width: 233px;
    }
}

@media (min-width: 320px) and (max-width:480px) {
    .dropdownLine {
        width: 265px;
    }

    .dropDown {
        min-width: 263px;
    }
}