.container {
    width: 95%;
    height: 53px;
    border-radius: 8px;
    background-color: #ffffff;
    display:flex;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
}

.resultCount {
    width: 90%;
    margin-left: 32px;
    height: 100%;
    align-self: flex-start;
    align-content: center;
    align-items: center;
    display: flex;
    /* border: 2px solid black; */
}

.resultCountText {
    font-family: 'BentonSans Regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #53565a;
}

.sort {
    justify-content: flex-end;
    width: 10%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: 32px;
    /* border: 2px solid red; */
}

.sortBtn {
    display: flex;
    align-items: center;
    /* align-content: center;
    flex-direction: row;
    justify-content: flex-end; */
}

.sortText {
    justify-self: flex-end;
    font-family: 'BentonSans Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #006fcf;
}

.sortIcon {
    width: 12px;
    height: 12px;
    margin-left: 9px;
}

.menuContainer {
    position: relative;
}

@media only screen and (min-width: 300px) and (max-width: 850px){
    .container {
        width: auto;
        max-width: 374px;
    }
}